import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const About = () => {
  const title = "Dr Pepper Addiction";
  const description =
    "What's more, exactly when you figured wonderful flavor couldn't get any more amazing er. This zero-sugar enamors your tastebuds with the notable mix of 23 flavors. It's the delectable twofold take your tastebuds merit.";

  return (
    <Layout>
      <Seo title="Dr Pepper Addiction" description="Dr Pepper Addiction is a fan site dedicated to those who have a deep craving for the Dr Pepper Soda" />
      <Container>
        <Row>
          <Col>
            <h1>About DrPepperAddiction.com</h1>
            <p>
              This is a fan site created to cater for Dr Pepper Addicts. It is an informative site that provides a view back on the history of the
              <Link className="nav-link d-inline " to="/drpepper/">
                Dr Pepper Soda
              </Link>
              , and information that will help you to make healthy decisions about your drinking habits.
            </p>
            <p>The site also provides affiliate links so that we can cover the costs of hosting and build on the information provided.</p>
            <p>I hope you enjoy your visit and drink responsibly, ThePepperMan.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;
